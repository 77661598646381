<template>
  <div
    class="lug-carousel"
    :class="{ fade: slideType == 'fade', slide: slideType != 'fade', 'animation': animation }"
  >
    <div class="lug-carousel-viewport" ref="viewport">
      <ul class="lug-carousel-list" ref="list" v-if="slideType == 'fade'">
        <slot />
      </ul>
      <ul
        class="lug-carousel-list"
        ref="list"
        v-if="slideType != 'fade'"
        :class="{ 'slide-bar': slideBar }"
        :style="
          'width:' +
          100 * length +
          '%; transform: translateX(' +
          (-100 / length) * idx +
          '%);'
        "
      >
        <slot />
      </ul>
      <div
        class="lug-carousel-arrow next"
        ref="arrowNext"
        @click="slideNext"
        v-if="arrow && items.length > 1"
      >
        <img src="/img/arrow-right@3x.png" v-if="this.idx == this.length - 1" />
        <img src="/img/arrow-right-darker.png" v-else />
      </div>
      <div
        class="lug-carousel-arrow prev"
        ref="arrowPrev"
        @click="slidePrev"
        v-if="arrow && items.length > 1"
      >
        <img src="/img/arrow-right@3x.png" v-if="this.idx == 0" />
        <img src="/img/arrow-right-darker.png" v-else />
      </div>
      <div
        class="lug-carousel-pager-zoomable"
        v-if="pager && items.length > 1 && zoomable"
      >
        <span
          v-for="dot in length"
          :class="{ on: dot == idx + 1 }"
          @click="slideTo(dot - 1)"
        ></span>
      </div>
      <div class="lug-carousel-pager" v-else-if="pager && items.length > 1">
        <span
          v-for="dot in length"
          :class="{ on: dot == idx + 1 }"
          @click="slideTo(dot - 1)"
        ></span>
      </div>
      <div
        class="lug-carousel-slide-bar"
        v-else-if="items.length > 1 && slideBar"
      >
        <span
          v-for="bar in length"
          :class="{ on: bar == idx + 1 }"
          @click="slideTo(bar - 1)"
          :style="slideBarStyle"
        ></span>
      </div>
      <!-- <div class="lug-carousel-increment-pager" v-else-if="incrementPager">
        <span :style="incrementPagerStyle"></span>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "slideType",
    "arrow",
    "pager",
    "zoomable",
    "slideBar",
    "incrementPager",
    "delayAnimation"
  ],
  data() {
    var idx = 0;
    var length = 0;
    var items = [];
    var dragEventNames = {
      start: ["mousedown", "touchstart"],
      move: ["mousemove", "touchmove"],
      end: ["mouseup", "touchend"],
    };
    var dragStartX = 0;
    var dragStartY = 0;
    var dragMoveX = 0;
    var dragMoveY = 0;

    return {
      idx,
      length,
      items,
      dragEventNames,
      dragStartX,
      dragStartY,
      dragMoveX,
      dragMoveY,
      animation: this.$props.delayAnimation > 0 ? false : true,
    };
  },
  mounted() {
    this.idx = 0;
    this.items = this.$refs.list.querySelectorAll(".lug-carousel-item");
    if (this.items.length) {
      this.items[this.idx].classList.add("on");
      this.length = this.items.length;
    }
    if (this.items.length > 1) {
      this.bindEvent();
    }

    if (this.$props.delayAnimation > 0) {
      setTimeout(() => {
        this.$data.animation = true;
      }, this.$props.delayAnimation * 1000);
    }
  },
  watch: {
    idx(value) {
      this.$emit("change", value);
    },
  },
  computed: {
    slideBarStyle() {
      const widthText =
        this.items.length > 0 ? `calc(100% / ${this.items.length})` : "";

      return {
        width: widthText,
      };
    },
    incrementPagerStyle() {
      const widthText =
        this.items.length > 0
          ? `calc(100% / ${this.items.length} * ${this.idx + 1})`
          : "0";

      return {
        width: widthText,
      };
    },
  },
  methods: {
    slideTo(idx) {
      if (this.idx == idx) {
        this.$refs.list.style.transform =
          "translateX(" + -1 * (100 / this.length) * this.idx + "%)";
      }
      this.idx = idx;
      for (var i = 0; i < this.items.length; i++) {
        this.items[i].classList.remove("on");
      }
      this.items[this.idx].classList.add("on");
    },
    slideNext() {
      this.slideTo(this.idx === this.length - 1 ? this.idx : this.idx + 1);
    },
    slidePrev() {
      this.slideTo(this.idx === 0 ? this.idx : this.idx - 1);
    },
    bindEvent() {
      this.$refs.viewport.addEventListener(
        this.dragEventNames.start[
          this.$store.state.config.isTouchDevice ? 1 : 0
        ],
        (e) => {
          if (
            this.$store.state.config.isTouchDevice ||
            (!this.$store.state.config.isTouchDevice && e.which == 1)
          )
            this.bindDragStart(e);
        }
      );
    },
    bindDragStart(e) {
      this.dragStartX = this.$store.state.config.isTouchDevice
        ? e.touches[0].clientX
        : e.x;
      this.dragStartY = this.$store.state.config.isTouchDevice
        ? e.touches[0].clientY
        : e.y;
      window.addEventListener(
        this.dragEventNames.move[
          this.$store.state.config.isTouchDevice ? 1 : 0
        ],
        this.bindDragMove
      );
      window.addEventListener(
        this.dragEventNames.end[this.$store.state.config.isTouchDevice ? 1 : 0],
        this.bindDragEnd
      );
    },
    bindDragMove(e) {
      this.dragMoveX = this.$store.state.config.isTouchDevice
        ? this.dragStartX - e.touches[0].clientX
        : this.dragStartX - e.x;
      this.dragMoveY = this.$store.state.config.isTouchDevice
        ? this.dragStartY - e.touches[0].clientY
        : this.dragStartY - e.y;
      this.$refs.list.style.transitionDuration = "0s";

      const outOnLeft = this.idx == 0 && this.dragMoveX < 0;
      const outOnRight = this.idx == this.length - 1 && this.dragMoveX > 0;

      let transitionX =
        -1 * (this.dragMoveX + this.idx * this.$refs.viewport.offsetWidth);

      if (outOnLeft) {
        transitionX =
          (1 - Math.pow(1 - Math.abs(this.dragMoveX / 1000), 2)) *
          (this.$refs.viewport.offsetWidth / 4);
      } else if (outOnRight) {
        transitionX =
          -1 *
          (this.idx * this.$refs.viewport.offsetWidth +
            (1 - Math.pow(1 - Math.abs(this.dragMoveX / 1000), 2)) *
              (this.$refs.viewport.offsetWidth / 4));
      }

      this.$refs.list.style.transform = "translateX(" + transitionX + "px)";

      if (Math.abs(this.dragMoveX) > 2 * Math.abs(this.dragMoveY)) {
        document.body.style.overflow = "hidden";
      }
    },
    bindDragEnd(e) {
      this.$refs.list.style.transitionDuration = "";

      if (this.dragMoveX > this.$refs.viewport.offsetWidth / 4) {
        this.slideNext();
      } else if (this.dragMoveX < (-1 * this.$refs.viewport.offsetWidth) / 4) {
        this.slidePrev();
      } else {
        this.slideTo(this.idx);
      }

      this.dragStartX = 0;
      this.dragMoveX = 0;
      document.body.style.overflow = "";
      window.removeEventListener(
        this.dragEventNames.move[
          this.$store.state.config.isTouchDevice ? 1 : 0
        ],
        this.bindDragMove
      );
      window.removeEventListener(
        this.dragEventNames.end[this.$store.state.config.isTouchDevice ? 1 : 0],
        this.bindDragEnd
      );
    },
  },
};
</script>
