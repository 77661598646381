<template>
  <div id="main-landing-page">
    <div id="float-header" class="header">
      <div class="container">
        <div class="image-area">
          <img src="/img/logo-mint-new.svg" />
        </div>

        <div class="gnb-area">
          <ul>
            <li class="test-test" @click="onNavigate('Home')">{{ $__t('고객') }}</li>
            <li @click="onNavigate('HostLanding')">{{ $__t('호스트') }}</li>
            <li @click="downloadFile()">{{ $__t('서비스 소개서') }}</li>
          </ul>
        </div>

        <div class="button-area">
          <div class="language-select" style="display: flex; align-items: center; padding-left: 1.75rem">
            <select id="language-select-form" :value="lang" @change="switchLanguage($event.target.value)">
              <option value="ko" :selected="lang == 'ko'">한국어</option>
              <option value="en" :selected="lang == 'en'">English(US)</option>
              <option value="cn" :selected="lang == 'cn'">中文(簡体)</option>
              <option value="hk" :selected="lang == 'hk'">中文(繁體)</option>
              <option value="jp" :selected="lang == 'jp'">日本語</option>
              <option value="vn" :selected="lang == 'vn'">Tiếng Việt</option>
            </select>
            <img
              src="/img/icon/header-language-blue.svg"
              style="display: inline-block; margin-right: 0.5rem; width: 22px; height: auto; vertical-align: top"
            />
            {{ lang == 'ko' || lang == 'kr' ? '한국어' : '' }}
            {{ lang == 'en' ? 'English(US)' : '' }}
            {{ lang == 'cn' ? '中文(簡体)' : '' }}
            {{ lang == 'hk' ? '中文(繁體)' : '' }}
            {{ lang == 'jp' ? '日本語' : '' }}
            {{ lang == 'vn' ? 'Tiếng Việt' : '' }}
            <i class="fa fa-angle-down"></i>
          </div>
        </div>
        <!-- <div class="nav-area">
          <nav>
            <button @click="onClickLogin">
              {{ $__t("로그인") }}
            </button>
          </nav>
        </div> -->
      </div>
    </div>

    <div id="main-intro-section" class="section">
      <div class="container">
        <div class="header">
          <div class="image-area">
            <progressive-img :blur="30" :ratio="1" src="/img/logo-white-new.svg" />
          </div>

          <div class="gnb-area">
            <ul>
              <li class="test-test" @click="onNavigate('Home')">{{ $__t('고객') }}</li>
              <li @click="onNavigate('HostLanding')">{{ $__t('호스트') }}</li>
              <li @click="downloadFile()">{{ $__t('서비스 소개서') }}</li>
            </ul>
          </div>

          <div class="button-area">
            <div class="language-select" style="display: flex; align-items: center; padding-left: 1.75rem">
              <select id="language-select-form" :value="lang" @change="switchLanguage($event.target.value)">
                <option value="ko" :selected="lang == 'ko'">한국어</option>
                <option value="en" :selected="lang == 'en'">English(US)</option>
                <option value="cn" :selected="lang == 'cn'">中文(簡体)</option>
                <option value="hk" :selected="lang == 'hk'">中文(繁體)</option>
                <option value="jp" :selected="lang == 'jp'">日本語</option>
                <option value="vn" :selected="lang == 'vn'">Tiếng Việt</option>
              </select>

              <img
                src="/img/icon/header-language-white.svg"
                style="display: inline-block; margin-right: 0.5rem; width: 22px; height: auto; vertical-align: top"
              />
              {{ lang == 'ko' || lang == 'kr' ? '한국어' : '' }}
              {{ lang == 'en' ? 'English(US)' : '' }}
              {{ lang == 'cn' ? '中文(簡体)' : '' }}
              {{ lang == 'hk' ? '中文(繁體)' : '' }}
              {{ lang == 'jp' ? '日本語' : '' }}
              {{ lang == 'vn' ? 'Tiếng Việt' : '' }}
              <i class="fa fa-angle-down"></i>
            </div>
          </div>

          <!-- <div class="nav-area">
            <nav>
              <button @click="onClickLogin">
                {{ $__t("로그인") }}
              </button>
            </nav>
          </div> -->
        </div>

        <div class="body">
          <div class="intro-left">
            <div class="textarea">
              <div class="title">
                {{ $__t('Store Renting Spaces') }}
                {{ $__t('Luggage Storage Service') }}<br />
                <strong>{{ $__t('LugStay') }}</strong>
              </div>
              <div class="description">
                {{ $__t('Keep your all kinds of items anytime, anywhere in local stores on your mobile phone.') }}
              </div>
            </div>
            <div class="button-area">
              <a href="https://bwc.lugstay.com/kntojg">
                <img src="/img/google-play-button.png" />
              </a>
              <a href="https://apps.apple.com/kr/app/lugstay/id1472429427">
                <img src="/img/app-store-button.png" />
              </a>
            </div>
          </div>
          <div class="intro-right">
            <div class="image-area">
              <progressive-img :blur="30" :ratio="1" src="/img/app-device-new.png" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="main-necessary-section" class="section">
      <div class="container">
        <div class="header">
          <div class="title">
            {{ $__t('The moments you need LugStay') | capitalizeFirstLetter }}
          </div>
        </div>

        <div class="body">
          <div class="row">
            <div class="container"></div>
            <div class="container">
              <div class="image">
                <progressive-img src="/img/moment-shopping.png" />
              </div>
              <div class="text">
                {{ $__t('shopping items become hassle') | capitalizeFirstLetter }}
              </div>
            </div>
            <div class="container">
              <div class="image">
                <progressive-img src="/img/moment-travel.png" />
              </div>
              <div class="text">
                {{ $__t('feel liberated while traveling') | capitalizeFirstLetter }}
              </div>
            </div>
            <div class="container">
              <div class="image">
                <progressive-img src="/img/moment-gift.png" />
              </div>
              <div class="text">
                {{ $__t('give a precious thing to loved one') | capitalizeFirstLetter }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="container">
              <div class="image">
                <progressive-img src="/img/moment-delivery.png" />
              </div>

              <div class="text">
                {{ $__t('receive a delivery safe') | capitalizeFirstLetter }}
              </div>
            </div>
            <div class="container">
              <div class="image">
                <progressive-img src="/img/moment-wayback.png" />
              </div>

              <div class="text">
                {{ $__t('plans to do on your way back home') | capitalizeFirstLetter }}
              </div>
            </div>
            <div class="container">
              <div class="image">
                <progressive-img src="/img/moment-large.png" />
              </div>

              <div class="text">
                {{ $__t('be released from big items') | capitalizeFirstLetter }}
              </div>
            </div>
            <div class="container">
              <div class="image">
                <progressive-img src="/img/moment-secondhand.png" />
              </div>
              <div class="text">
                {{ $__t('safe second-hand deal') | capitalizeFirstLetter }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="main-step-section" class="section">
      <div class="container">
        <div class="header">
          <ul>
            <li :class="`activated-${mainStepSection.activeIndex}`">
              <button type="button" @click="activateMainStep(0)">
                <div class="circle">1</div>
                <div class="textarea">
                  <span class="text">{{ $__t('예약하고') }}</span>
                </div>
              </button>
            </li>
            <li :class="`activated-${mainStepSection.activeIndex}`">
              <button type="button" @click="activateMainStep(1)">
                <div class="circle">2</div>
                <div class="textarea">
                  <span class="text">{{ $__t('맡기고') }}</span>
                </div>
              </button>
            </li>
            <li :class="`activated-${mainStepSection.activeIndex}`">
              <button type="button" @click="activateMainStep(2)">
                <div class="circle">3</div>
                <div class="textarea">
                  <span class="text">{{ $__t('찾기') }}</span>
                </div>
              </button>
            </li>
          </ul>
        </div>

        <div class="body">
          <lug-carousel ref="carousel" slideType="slide" @change="mainStepSection.activeIndex = $event">
            <lug-carousel-item>
              <div class="container">
                <div class="header">
                  <div class="step">STEP 01</div>
                </div>
                <div class="body">
                  <div class="image-area">
                    <img src="/img/checkin-step1-1.png" />
                  </div>
                  <div class="text-area">
                    <div class="headline" v-html="$__t('{main-step-section}.step1.header')"></div>
                    <div class="article">
                      {{ $__t('{main-step-section}.step1.body') }}
                    </div>
                  </div>
                </div>
              </div>
            </lug-carousel-item>
            <lug-carousel-item>
              <div class="container">
                <div class="header">
                  <div class="step">STEP 02</div>
                </div>
                <div class="body">
                  <div class="image-area">
                    <img src="/img/checkin-step2-1.png" />
                  </div>
                  <div class="text-area">
                    <div class="headline" v-html="$__t('{main-step-section}.step2.header')"></div>
                    <div class="article">
                      {{ $__t('{main-step-section}.step2.body') }}
                    </div>
                  </div>
                </div>
              </div>
            </lug-carousel-item>
            <lug-carousel-item>
              <div class="container">
                <div class="header">
                  <div class="step">STEP 03</div>
                </div>
                <div class="body">
                  <div class="image-area">
                    <img src="/img/checkout-step1-1.png" />
                  </div>
                  <div class="text-area">
                    <div class="headline" v-html="$__t('{main-step-section}.step3.header')"></div>
                    <div class="article">
                      {{ $__t('{main-step-section}.step3.body') }}
                    </div>
                  </div>
                </div>
              </div>
            </lug-carousel-item>
          </lug-carousel>
        </div>
      </div>
    </div>

    <div id="main-safe-section" class="section">
      <div class="container">
        <div class="header">
          <div class="title">
            {{ $__t('{main-safe-section}.section.title') }}
          </div>
        </div>

        <div class="body">
          <div class="container">
            <div class="image">
              <img src="/img/main-safe-1.png" />
            </div>
            <div class="primary">{{ $__t('쉽고 편한 사용성') }}</div>
            <div class="secondary">
              {{ $__t('QR 코드로 물품 보관 및 찾기가 간편합니다.') }}
            </div>
          </div>

          <div class="container">
            <div class="image">
              <img src="/img/main-safe-2.png" />
            </div>
            <div class="primary">{{ $__t('안전한 보관') }}</div>
            <div class="secondary">
              {{ $__t('CCTV의 별도의 보관 공간이 있는 호스트만 선별했습니다.') }}
            </div>
          </div>

          <div class="container">
            <div class="image">
              <img src="/img/main-safe-3.png" />
            </div>
            <div class="primary">{{ $__t('Simple Payment') }}</div>
            <div class="secondary">
              {{ $__t('Register your card and pay as much as you used.') }}
            </div>
          </div>

          <div class="container">
            <div class="image">
              <img src="/img/main-safe-4.png" />
            </div>
            <div class="primary">{{ $__t('분실 파손 보험') }}</div>
            <div class="secondary">
              {{ $__t('분실 혹은 손상 발견 시 최대 100만원 보상해드립니다.') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="main-review-section" class="section">
      <div class="container">
        <div class="header">
          <div class="title">{{ $__t('{home-review-section}.title') }}</div>
        </div>

        <div class="body">
          <div class="carousel">
            <CarouselCard :autoplay="false" :autosize="true" type="card" arrow="always" indicatorPosition="none">
              <CarouselCardItem v-for="(src, i) in getReviewImageByViewport" :key="i">
                <img :src="src" />
              </CarouselCardItem>
            </CarouselCard>
          </div>
        </div>
      </div>
    </div>

    <div id="main-host-section" class="section">
      <div class="container">
        <div class="header">
          <div class="primary">
            {{ $__t('You can earn extra money and attract new customers by renting your storage space in your store.') }}
          </div>
          <div class="secondary">
            {{ $__t('별도의 비용 없이 호스트가 될 수 있습니다.') }}
          </div>
        </div>
        <div class="body">
          <div class="container">
            <div class="image">
              <img src="/img/main-host-1.png" />
            </div>
            <div class="text">{{ $__t('무료 가입') }}</div>
          </div>
          <div class="container">
            <div class="image">
              <img src="/img/main-host-2.png" />
            </div>
            <div class="text">{{ $__t('홍보효과') }}</div>
          </div>
          <div class="container">
            <div class="image">
              <img src="/img/main-host-3.png" />
            </div>
            <div class="text">{{ $__t('추가적인 수익창출') }}</div>
          </div>
          <div class="container">
            <div class="image">
              <img src="/img/main-host-4.png" />
            </div>
            <div class="text">{{ $__t('고객유입') }}</div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="header">
          <div class="primary">{{ $__t('Extra space in your store?') }}</div>
        </div>
        <div class="body button-area">
          <router-link :to="{ name: 'HostLanding' }">
            <button type="button">{{ $__t('호스트 신청하기') }}</button>
          </router-link>
        </div>
      </div>
    </div>

    <div id="main-partners-section" class="section">
      <div class="container">
        <div class="header">
          <div class="title">PARTNERS</div>
        </div>
        <div class="body">
          <ul>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/partners-logo-kto.png" />
              </div>
            </li>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/partners-logo-sto.png" />
              </div>
            </li>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/partners-logo-mss.png" />
              </div>
            </li>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/partners-logo-sk-telecom.png" />
              </div>
            </li>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/partners-logo-incheon-airport.png" />
              </div>
            </li>
            <li>
              <div class="image">
                <progressive-background :aspect-ratio="0.35" src="/img/main-partners-hub.png" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div id="main-contact-section" class="section">
      <div class="container">
        <div class="body">
          <div>
            {{ $__t('비즈니스 제휴/협업에 관해서\n문의사항이 있으신가요?') }}
          </div>
          <div>
            <span @click="onContact()">
              {{ $__t('제휴/협업 문의') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LugCarousel from '@/components/modules/LugCarousel/Body';
import LugCarouselItem from '@/components/modules/LugCarousel/Item';
import CarouselCard from '@/components/modules/LugCarouselCard/Body';
import CarouselCardItem from '@/components/modules/LugCarouselCard/Item';
import { Language } from '@/modules/language/index.js';
import _ from 'lodash';

export default {
  // name: "PcLanding",

  data() {
    return {
      lang: localStorage.getItem('lugstay_language') || 'ko',
      mainStepSection: {
        activeIndex: 0
      },
      isFloatedHeaderShown: false
    };
  },

  computed: {
    getReviewImageByViewport() {
      return ['/img/home-review-p01.png', '/img/home-review-p02.png', '/img/home-review-p03.png', '/img/home-review-p04.png'];
    },

    isHost() {
      // return !!this.$store.state.auth.host_id;
      return false;
    }
  },

  methods: {
    activateMainStep(index) {
      this.mainStepSection.activeIndex = index;

      this.$refs.carousel.slideTo(index);
    },

    toggleFloatedHeader: _.debounce(function () {
      const mainIntroSectionHeader = $('#main-intro-section > .container > .header');

      const mainIntroSectionHeaderHeight = mainIntroSectionHeader.offset().top + mainIntroSectionHeader.outerHeight();
      const scrollTop = $(window).scrollTop();
      const floatedHeaderHeight = $('#float-header', this.$el).outerHeight();

      const toggleFloatedHeader = mainIntroSectionHeaderHeight - floatedHeaderHeight - 38 <= scrollTop;

      if (toggleFloatedHeader === true) {
        if (this.isFloatedHeaderShown) return;

        $('#float-header.header', this.$el).addClass('show');
        this.isFloatedHeaderShown = true;
      } else {
        if (!this.isFloatedHeaderShown) return;

        $('#float-header.header', this.$el).removeClass('show');
        this.isFloatedHeaderShown = false;
      }
    }, 50),

    setLanguage(locale) {
      localStorage.setItem('lugstay_language', locale);

      this.$analytics.setLanguage(locale);

      window.location = '/';

      // if (!this.$store.state.auth.gid) {
      //   localStorage.setItem("lugstay_language", locale);
      //   this.$analytics.setLanguage(locale);
      //   window.location = "/";
      //   return;
      // }

      // this.$store
      //   .dispatch("auth/edit", {
      //     user_language: locale,
      //   })
      //   .finally(() => {
      //     localStorage.setItem("lugstay_language", locale);
      //     this.$analytics.setLanguage(locale);
      //     window.location = "/";
      //   });
    },

    switchLanguage(locale) {
      this.$store.commit('loading/SET_TRUE');

      setTimeout(() => {
        this.$store.commit('loading/SET_FALSE');
      }, 1000);

      this.$store.commit('SET_LANGUAGE', locale);

      const languageSet = {
        ko: 'ko',
        en: 'En',
        hk: 'ch_traditional',
        cn: 'ch_Simplified Chinese',
        jp: 'jp',
        vn: 'vietnamese',
        system: 'system'
      };

      this.$analytics.logEvent('language change', { language: languageSet[locale] });

      Language.setLanguage(locale);

      this.lang = locale;
    },

    onClickLogin() {
      location.href = 'https://app.lugstay.com';
    },

    onNavigate(e) {
      this.$router.push({ name: e });
    },

    downloadFile() {
      const url = 'https://cdn.u-hoo.com/uploaded/럭스테이(Lugstay)_서비스소개서.pdf';
      window.open(url);
    },

    onContact() {
      window.location.href = 'mailto:biz@lugstay.com';
    }
  },

  async mounted() {
    await this.$nextTick();
    this.toggleFloatedHeader();

    // this.$store.dispatch("custom/checkNoticePopups");

    window.addEventListener('scroll', this.toggleFloatedHeader, {
      passive: true
    });
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.toggleFloatedHeader, {
      passive: true
    });
  },

  components: {
    LugCarousel,
    LugCarouselItem,
    CarouselCard,
    CarouselCardItem
  }
};
</script>
