<template>
  <div
    v-show="ready"
    :style="{
      msTransform: `translateX(${translate}) translateY(-50%) scale(${scale})`,
      webkitTransform: `translateX(${translate}) translateY(-50%) scale(${scale})`,
      transform: `translateX(${translate}) translateY(-50%) scale(${scale})`,
      top: '50%',
    }"
    :class="{
      'is-active': active,
      'carousel-card-item-card': $parent.type === 'card',
      'is-in-stage': inStage,
      'is-hover': hover,
      'is-animating': animating,
    }"
    class="carousel-card-item"
    @click="handleItemClick"
  >
    <div
      v-if="$parent.type === 'card'"
      v-show="!active"
      class="carousel-card-mask"
    ></div>
    <slot></slot>
  </div>
</template>
<script>
const CARD_SCALE = 0.83;
export default {
  name: "CarouselCardItem",
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      hover: false,
      translate: 0,
      left: 0,
      right: 0,
      scale: 1,
      active: false,
      ready: false,
      inStage: false,
      animating: false,
    };
  },
  created() {
    this.updateItems();
  },
  destroyed() {
    this.updateItems();
  },
  methods: {
    processIndex(index, activeIndex, length) {
      if (activeIndex === 0 && index === length - 1) {
        return -1;
      } else if (activeIndex === length - 1 && index === 0) {
        return length;
      } else if (index < activeIndex - 1 && activeIndex - index >= length / 2) {
        return length + 1;
      } else if (index > activeIndex + 1 && index - activeIndex >= length / 2) {
        return -2;
      }
      return index;
    },
    calculateTranslate(index, activeIndex, parentWidth) {
      let width = this.$el.getBoundingClientRect().width;
      // let originalIndex = this.$parent.items.indexOf(this);
      // const length = this.$parent.items.length;
      // const isLeft = ((originalIndex == activeIndex - 1) ||(originalIndex == length - 1 && activeIndex == 0))
      // const isRight = ((originalIndex == activeIndex + 1) || (activeIndex == length - 1 && originalIndex == 0))

      if (this.inStage) {
        return parentWidth * ((2 - CARD_SCALE) * (index - activeIndex) + 1) / 4
      }else {
        return parentWidth * (1) / 4
      }

    },
    translateItem(index, activeIndex, oldIndex) {
      const parentWidth = this.$parent.$el.getBoundingClientRect().width;
      const length = this.$parent.items.length;
      let originalIndex = index;
      if (this.$parent.type !== "card" && oldIndex !== undefined) {
        this.animating = index === activeIndex || index === oldIndex;
      }
      if (index !== activeIndex && length > 2) {
        index = this.processIndex(index, activeIndex, length);
      }
      if (this.$parent.type === "card") {
        this.inStage = Math.round(Math.abs(index - activeIndex)) <= 1;
        this.active = index === activeIndex;
        this.translate =
          this.calculateTranslate(
            index,
            activeIndex,
            parentWidth
          ) + "px";
        this.scale = this.active ? 1 : CARD_SCALE;
      } else {
        this.active = index === activeIndex;
        this.translate = parentWidth * (index - activeIndex);
      }
      this.ready = true;
    },
    handleItemClick() {
      const parent = this.$parent;
      if (parent && parent.type === "card") {
        const index = parent.items.indexOf(this);
        parent.setActiveItem(index);
      }
    },
    updateItems() {
      this.$parent && this.$parent.updateItems();
    },
  },
};
</script>
