var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ready),expression:"ready"}],staticClass:"carousel-card-item",class:{
    'is-active': _vm.active,
    'carousel-card-item-card': _vm.$parent.type === 'card',
    'is-in-stage': _vm.inStage,
    'is-hover': _vm.hover,
    'is-animating': _vm.animating,
  },style:({
    msTransform: `translateX(${_vm.translate}) translateY(-50%) scale(${_vm.scale})`,
    webkitTransform: `translateX(${_vm.translate}) translateY(-50%) scale(${_vm.scale})`,
    transform: `translateX(${_vm.translate}) translateY(-50%) scale(${_vm.scale})`,
    top: '50%',
  }),on:{"click":_vm.handleItemClick}},[(_vm.$parent.type === 'card')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.active),expression:"!active"}],staticClass:"carousel-card-mask"}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }